
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { mapActions, mapState } from "vuex";
import { FeedbackType } from "@/schemas/Enums";

@Component({
  components: { Score },
  methods: {
    ...mapActions("session", ["getSessionWordNumber"]),
    ...mapState("session", ["session"]),
  },
})
//    ...mapActions("session", ["getSessionWordNumber"]),
export default class Conclusion extends Vue {
  fullyLoaded = false;
  participation = [];

  message1 =
    "La communication au sein de votre équipe a été équitable entre tous ses participant·es.";
  message2 =
    "La communication n'a pas été répartie de manière égale entre tous les participant·es de votre \n" +
    "équipe. ";

  data(): any {
    return {
      sessionFull : ''
    }
  }

  created(): void {
    this.getSessionWordNumber({
      sessionId: this.$route.params.sessionId,
      next: (participation) => {
        let participationTemp = participation.map((part) => part.participation);
        const participationNoDuplicate = participationTemp.filter((obj1, i, arr) =>
          arr.findIndex(obj2 => (obj2.username === obj1.username)) === i
        );
        this.participation = participationNoDuplicate
        this.fullyLoaded = true;
      },
    });
    this.$store.dispatch("session/fetchFullSession", {
      sessionId: this.$route.params.sessionId,
      next: (session) => {
        this.sessionFull=session
      },
    });
  }

  get partProp(): number[] {
    let sum = 0;
    this.participation.forEach((num) => {
      sum += num;
    });
    return [...this.participation.map((part) => (part * 100) / sum)];
  }

  get message(): string {
    let nbPlayers = this.participation.length;
    let optimalRepartition = 100 / nbPlayers;
    let max = optimalRepartition + (1 + 1 / nbPlayers) * 5;
    let min = optimalRepartition - (1 + 1 / nbPlayers) * 5;
    if (this.partProp.every((part) => part <= max && part >= min)) {
      return this.message1;
    }
    return this.message2;
  }


  get displayFeedbackMessage(): boolean {
    if (this.sessionFull.setting) {
      let settings = this.sessionFull.setting.feedback_details.find(
        (setting: {
          type: FeedbackType;
          display_feedback_message: boolean;
          display_score?: boolean;
          display_indiv_scores?: boolean;
          display_group_score?: boolean;
        }) => setting.type === FeedbackType.Participation
      );
      return settings ? settings.display_feedback_message : false;
    }

    return false;
  }

}
